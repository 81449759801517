import ajax2 from '@/utils/ajax2';
import axios from 'axios';

/**
 * 检查设备绑定
 */
export function checkCallDevice(data: { DeviceCode: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/CallDevice/ClientCheckCallDevice',
    method: 'POST',
    data,
  });
}

/**
 * 上传录音文件
 */
export function uploadAudioFile(formData: FormData) {
  return axios.post('http://116.62.4.149:15000/File/Upload', formData);
}

export interface CallInfoParams {
  StartTime: string;
  AnswerTime: string; // 接通时间
  OssPath: string;
  EndTime: string;
  CallNumber: string;
  DescNumber: string;
}
/**
 * 检查号码限制
 */
export function baiHeUploadCallInfo(data: CallInfoParams) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/CallDevice/BaiHeUploadCallInfo',
    method: 'POST',
    data,
  });
}

/**
 * 查询卡是否绑定
 */
export function checkDeviceCardIsBind(data: { CardCode: string }) {
  return ajax2<{ CallNumber: string; deleteFlag: boolean }>({
    url: '/FcomHuihu/V1/ClientApi/CallDevice/GetDeviceCardIsBind',
    method: 'POST',
    data,
  });
}

/**
 * 卡绑定主叫
 */
export function bindDeviceCard(data: { CardCode: string; CallNumber: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/CallDevice/BindDeviceCard',
    method: 'POST',
    data,
  });
}
